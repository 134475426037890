<template>
  <div class="all-assets-wrap">
    <el-input
      class="align-self-start mb-10"
      style="width:300px;"
      placeholder="输入单点用户名称/ID搜索"
      v-model="keyword"
    >
      <el-button
        @click="handleSearch"
        slot="append"
        icon="el-icon-search"
      ></el-button>
    </el-input>
    <cus-table
      ref="cusTableRef"
      :tableParams="tableParams"
      :tableHeightData="tableHeightData"
      :tableData="tableData"
      :colData="colData"
      :loading="loading"
    >
      <template v-slot:cusheader="{ scope, col }">
        <div v-if="col.prop != 'user'">
          <p>{{ scope.column.label }}</p>
          <p>（总数/正常数/被封数（单位为个））</p>
        </div>
      </template>
      <template v-slot:colslot="{ scope, prop }">
        <div v-if="prop != 'user'">
          <p>
            <el-tooltip
              effect="dark"
              :content="`点击查看所有的${scope.column.label}`"
              placement="top"
            >
              <el-button
                @click="lookMore(scope.row, prop, 'total')"
                type="text"
                >{{ scope.row[prop].total }}</el-button
              ></el-tooltip
            ><span class="mx-10">/</span>
            <el-tooltip
              effect="dark"
              :content="`点击查看状态正常的${scope.column.label}`"
              placement="top"
              ><el-button
                @click="lookMore(scope.row, prop, 'normal')"
                type="text"
                >{{ scope.row[prop].normal }}</el-button
              ></el-tooltip
            ><span class="mx-10">/</span
            ><el-tooltip
              effect="dark"
              :content="`点击查看状态异常的${scope.column.label}`"
              placement="top"
              ><el-button
                @click="lookMore(scope.row, prop, 'abnormal')"
                type="text"
                >{{ scope.row[prop].abnormal }}</el-button
              ></el-tooltip
            >
          </p>
        </div>
      </template>
    </cus-table>
  </div>
</template>

<script>
import cusTable from "@/views/components/cusTable";
import mainPageAnalyze from "@/views/adAssets/components/mainPageAnalyze";
import { getMainPages } from "@/api/adAssets";
export default {
  components: {
    cusTable
  },
  props: ["isSync"],
  data() {
    return {
      page: 1,
      limit: 10,
      keyword: "",
      tableParams: {
        className: "",
        border: true,
        isTableHeight: true
      },
      // 表格
      tableHeightData: [
        { selector: ".navbar", cssProperty: "height" },
        { selector: ".app-main", cssProperty: "paddingTop" },
        { selector: ".app-main", cssProperty: "paddingBottom" },
        { selector: ".content", cssProperty: "paddingBottom" },
        { selector: ".cus-header", cssProperty: "height" },
        { selector: ".cus-header", cssProperty: "marginTop" },
        { selector: ".cus-header", cssProperty: "marginBottom" }
      ],
      tableData: [
        {
          user: "用户1",
          numAccount: { total: 5, normal: 3, abnormal: 2 },
          adAccount: { total: 5, normal: 3, abnormal: 2 },
          BM: { total: 5, normal: 3, abnormal: 2 },
          page: { total: 5, normal: 3, abnormal: 2 }
        },
        {
          user: "用户2",
          numAccount: { total: 5, normal: 3, abnormal: 2 },
          adAccount: { total: 5, normal: 3, abnormal: 2 },
          BM: { total: 5, normal: 3, abnormal: 2 },
          page: { total: 5, normal: 3, abnormal: 2 }
        }
      ],
      colData: [
        { label: "单点用户", prop: "user" },
        {
          label: "个号",
          prop: "numAccount"
        },
        {
          label: "广告帐号",
          prop: "adAccount"
        },
        {
          label: "商务管理平台",
          prop: "BM"
        },
        {
          label: "主页",
          prop: "page"
        }
      ],
      loading: false
    };
  },
  methods: {
    // 搜索
    handleSearch() {
      this.page = 1;
      this.$message("开发中...");
    },
    // 查看更多
    lookMore(row, prop, status) {
      switch (status) {
        case "total":
          this.$message("开发中...");
          break;
        case "normal":
          this.$message("开发中...");
          break;
        case "abnormal":
          this.$message("开发中...");
          break;
        default:
          break;
      }
    }
  },
  mounted() {},
  watch: {
    // 当同步成功后重新调用列表接口
    isSync(val) {
      if (val) {
        // this.getMainPages();
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.all-assets-wrap {
  color: $theme-color;
  .header {
    margin-bottom: 8px;
    .el-input {
      width: auto;
    }
    li {
      min-width: 150px;
      border-right: 1px solid #e5e6e9;
      padding: 0 15px;
      &:last-child {
        border-right: none;
      }
    }
    .count {
      font-weight: 700;
      margin-top: 10px;
    }
  }
}
</style>